import { CohortInstallByField } from './CohortInstallByField';
import { CohortSegmentByField } from './CohortSegmentByField';
import { CohortDisplayingTotalField } from './CohortDisplayingTotalField';
import { PeriodTypeField } from './PeriodTypeField';
import { StartMonthField } from './StartMonthField';

export const FIELDS_FOR_TYPE = {
  business_snapshot: ['startMonth', 'periodType'],
  revenue_waterfall: ['startMonth'],
  transactions: [],
  customers: [],
  cohorts: ['startMonth', 'cohortPeriodType', 'cohortInstallBy', 'cohortSegmentBy', 'cohortDisplayingTotal'],
  spreads: ['startMonth'],
  accounting_spreads: ['startMonth'],
  committed_revenue_waterfall: ['startMonth'],
  customers_waterfall: ['startMonth'],
  committed_customers_waterfall: ['startMonth'],
  pipeline_opportunities_cohorts: [],
  leads_cohorts: [],
  revenue_details: ['month'],
  seats_waterfall: ['startMonth'],
  committed_seats_waterfall: ['startMonth'],
  pricing_plans: [],
  usage_subscriptions: [],
};

export const COMMPONENT_FOR_FIELD = {
  startMonth: StartMonthField,
  periodType: [PeriodTypeField, { hasAllOption: true }],
  cohortInstallBy: CohortInstallByField,
  cohortSegmentBy: CohortSegmentByField,
  cohortDisplayingTotal: CohortDisplayingTotalField,
  cohortPeriodType: [PeriodTypeField, { name: 'cohortPeriodType' }],
  month: [StartMonthField, { name: 'month', label: 'Syncing data in month' }],
};
