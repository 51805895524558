import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { AppContext } from 'AppContext';

import { EVENTS } from 'consts/analytics';
import { ReactComponent as ArrowDown } from 'images/chevron-down.svg';
import { usePortal } from 'components/Portal';
import { Spacer } from 'components/Core';
import { Header, HEADER_TITLES } from 'shared/Layout';
import { HeaderTitle } from 'shared/Layout/Header/style';
import { BillingPopover } from 'shared/Layout/Navbar';
import { useSyncedExport } from 'shared/SyncedExports';
import { DisconnectedIntegrationBanner } from 'views/Billing/Common/DisconnectedIntegrationBanner';
import { UsageBasedEngineTabs } from 'views/Billing/UsageBasedEngine/Tabs';
import { USAGE_BASED_ENGINE_TABS } from 'views/Billing/UsageBasedEngine/Tabs/consts';
import { useAnalytics } from 'utils/hooks';

import { UsageBasedEngineMainContent } from './UsageBasedEngineMainContent';
import { HeaderActionsDropdown } from './HeaderActionsDropdown';
import { UploadActionsDropdown } from './UploadActionsDropdown';
import { HeaderTab } from '../../BillingHeader';
import { BillingProvider } from '../../BillingProvider';

const Wrapper = styled.div`
  padding: 0 40px 0px 40px;
`;

export const StyledArrow = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-left: 12px;
  box-shadow: 4px 4px 24px var(--primaryBlack4);
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  transition: all ease 0.4s;
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const UsageBasedEnginePage = () => {
  const { organizations } = useContext(AppContext);
  const { triggerRef, togglePortal, isPortalVisible, Portal, hidePortal } = usePortal();
  const { trackEvent } = useAnalytics();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const chosenTab = queryParameters.get('tab') ?? USAGE_BASED_ENGINE_TABS.USAGE_SUBSCRIPTIONS;

  useEffect(() => {
    trackEvent({ name: EVENTS.VISIT_USAGE_BASED_ENGINE_PAGE });
  }, [trackEvent]);

  const { SyncedExportInfoBar, SyncedExportModal, ExportButton } = useSyncedExport({
    orgId: organizations[0].id,
    type: chosenTab.replaceAll('-', '_'),
    buttonWhite: true,
  });

  return (
    <BillingProvider>
      <Wrapper>
        <Header
          headerLeft={
            <HeaderTitle weight={300} ref={triggerRef} onClick={togglePortal} cursor="pointer">
              {HEADER_TITLES.billing}: <HeaderTab>Usage-based Engine</HeaderTab>
              <StyledArrow active={isPortalVisible}>
                <ArrowDown />
              </StyledArrow>
              {isPortalVisible && (
                <Portal>
                  <BillingPopover onClose={hidePortal} />
                </Portal>
              )}
            </HeaderTitle>
          }
          headerRight={
            <>
              {chosenTab === USAGE_BASED_ENGINE_TABS.USAGE_SUBSCRIPTIONS ||
              chosenTab === USAGE_BASED_ENGINE_TABS.PRICING_PLANS ? (
                <>
                  <ExportButton />
                  <Spacer width="12px" />
                </>
              ) : null}
              <UploadActionsDropdown />
              <Spacer width="12px" />
              <HeaderActionsDropdown />
            </>
          }
        />

        <DisconnectedIntegrationBanner />

        <SyncedExportInfoBar />
        <SyncedExportModal />

        <UsageBasedEngineTabs />
      </Wrapper>
      <UsageBasedEngineMainContent />
    </BillingProvider>
  );
};
